<script>
import { logEvent, events } from '@/services/analytics'

export default {
  name: 'FieldviewIntegrated',

  mounted() {
    logEvent(events.integration.visitedPage)
  },

  methods: {
    disableIntegration() {
      this.$emit('disableIntegration')
    },
    async syncManual() {
      await this.$emit('syncManual')
    },
  },
}
</script>

<template>
  <v-row>
    <v-col class="pt-10 pb-5">
      <v-img
        alt="digifarmz-fieldview"
        width="244"
        style="margin: auto"
        src="img/logos/logo-digifarmz-fieldview.svg"
      />
      <v-card-title class="text-center justify-center fieldview-title-text">
        <span
          style="color: #1a2b46"
          v-html="$t('integration.fieldview.title_success')"
        >
        </span>
      </v-card-title>
      <v-card-text class="d-flex text-center justify-center">
        <div
          class="fieldview-integrated-description justify-center"
          v-html="$t('integration.fieldview.description_success')"
        ></div>
      </v-card-text>
      <v-card-text class="text-center">
        <v-btn
          class="fieldview-integration-button"
          color="#39AF49"
          dark
          @click="syncManual()"
        >
          <font-awesome-icon class="fa-md mr-2" icon="redo" />
          {{ $t('integration.sync') }}
        </v-btn>
      </v-card-text>
      <v-card-text class="text-center pt-0">
        <v-btn
          class="fieldview-link-button"
          color="#5b6459"
          text
          @click="$router.push('/my_farm')"
        >
          {{ $t('integration.fieldview.btnStart') }}
        </v-btn>
      </v-card-text>
      <div class="d-flex justify-end px-2">
        <v-btn
          class="fieldview-link-button"
          color="#788476"
          text
          @click="disableIntegration()"
        >
          {{ $t('integration.undo_integration') }}
          <font-awesome-icon class="fa-md ml-2" icon="sign-out-alt" />
        </v-btn>
      </div>
    </v-col>
  </v-row>
</template>

<style scoped>
.fieldview-title-text {
  font-family: 'Rubik', sans-serif;
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;
  color: #aab2a9;
}
.fieldview-integrated-description {
  width: 580px;
}
.fieldview-link-button {
  text-transform: none;
  text-decoration: underline;
  font-weight: 600;
  line-height: 20px;
  font-size: 14px;
}
</style>
