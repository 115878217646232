<script>
import { mapActions } from 'vuex'
import { logEvent, events } from '@/services/analytics'

export default {
  props: {
    url: { type: String, required: true },
  },

  methods: {
    ...mapActions('integrations', [
      'fetchIntegrations',
      'isLoadingIntegration',
    ]),

    integrationClick() {
      logEvent(events.integration.clickedButton)
    },
  },
}
</script>

<template>
  <v-row>
    <v-col class="fieldview-container-description">
      <v-img
        alt="digifarmz-fieldview"
        width="244"
        style="margin-left: 16px"
        src="img/logos/logo-digifarmz-fieldview.svg"
      ></v-img>
      <v-card-title class="fieldview-title-text">
        {{ $t('integration.fieldview.title_part') }}
        <span
          v-html="$t('integration.fieldview.fieldview')"
          style="color: #1a2b46"
        ></span>
      </v-card-title>
      <v-card-text
        class="fieldview-text"
        v-html="$t('integration.fieldview.description')"
      ></v-card-text>
      <v-card-text class="fieldview-description-2">
        {{ $t('integration.fieldview.description_2') }}
      </v-card-text>
      <v-card-text class="fieldview-description-list">
        <ul>
          <li>{{ $t('integration.fieldview.list.plots') }}</li>
          <li>{{ $t('integration.fieldview.list.farms') }}</li>
        </ul>
      </v-card-text>
      <v-card-text>
        <v-btn
          class="fieldview-integration-button"
          color="#39AF49"
          dark
          link
          exact
          :href="url"
          @click="integrationClick"
        >
          {{ $t('integration.fieldview.btn') }}&trade;
          <v-icon class="ml-1" small>mdi-file-send</v-icon>
        </v-btn>
      </v-card-text>
    </v-col>
    <v-col class="py-0">
      <v-img
        alt="fieldview"
        width="479"
        height="100%"
        src="img/img-integracao-fieldview-768.webp"
      ></v-img>
    </v-col>
  </v-row>
</template>

<style scoped>
.fieldview-title-text {
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 44px;
  color: #aab2a9;
}

.fieldview-text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #51594f;
}

.fieldview-container-description {
  padding: 40px 0 30px 100px;
}

.fieldview-description-2 {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
}

.fieldview-description-list {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  color: #788476;
}

.fieldview-integration-button {
  text-transform: none;
  font-weight: 600;
  line-height: 18px;
  font-size: 14px;
}
</style>
